import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { IPaymentAll, IPaymentUsers, PaymentsApi } from '../services/api/Payments';
import { Roles, TABLE_PER_PAGE } from '../utils/constants';
import { createTableData, createTableDataDefault } from '../utils/createTableData';
import { userStore } from './UserStore';
class PaymentsStore {
	tableData = createTableDataDefault<IPaymentAll | IPaymentUsers>();

	constructor() {
		makeAutoObservable(this);

		createTableData(this, this.query);
	}

	query = (params) => {
		const { role } = userStore.user!;

		return role === Roles.admin ? PaymentsApi.getAll(params) : PaymentsApi.get(params);
	};
}

export const paymentsStore = new PaymentsStore();
export const paymentsStoreContext = createContext(paymentsStore);
