import { Paginated } from '../../types';
import { get, post } from './methods';
export interface IPaymentAll {
	id: number;
	email: string;
	payDate: string;
	amount: number;
}

export interface IPaymentUsers {
	id: number;
	payDate: string;
	amount: number;
	package: string;
}
export interface ExcelParams {
	dateTo: string;
	dateFrom: string;
	email: string;
}
export interface IPaymentAmount {
	amountBTC: number;
	paymentUrl: string;
	transactionId: string;
}

export interface IDeletePayment {

}

export const PaymentsApi = {
	getAll: get<Paginated<IPaymentAll>>('/admin/payments'),
	get: get<Paginated<IPaymentUsers>>('/payments'),
	getExcel: get<string, ExcelParams>('/admin/export-payment-history'),
	create: post<IPaymentAmount>('/payments/create-payment'),
	addPayment: post<IPaymentAmount>('/admin/add-payment'),
	deletePayment: post<IDeletePayment>('/admin/delete-payment'),
};
