import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { IUser, UsersApi } from '../services/api/Users';
import { createTableData, createTableDataDefault } from '../utils/createTableData';

class UsersStore {
	tableData = createTableDataDefault<IUser>();

	constructor() {
		makeAutoObservable(this);

		createTableData(this, UsersApi.getAll);
	}

	blockUsers = async (usersIds: number[]) => {
		try {
			await UsersApi.blockUsers({ usersIds });

			this.tableData.dataSource = this.tableData.dataSource.map((user) => {
				if (usersIds.includes(user.id)) {
					user.isBlocked = true;
				}
				return user;
			});
		} catch (error) {
			console.log('error: ', error);
		}
	};

	unblockUsers = async (usersIds: number[]) => {
		try {
			await UsersApi.unblockUsers({ usersIds });

			this.tableData.dataSource = this.tableData.dataSource.map((user) => {
				if (usersIds.includes(user.id)) {
					user.isBlocked = false;
				}
				return user;
			});
		} catch (error) {
			console.log('error: ', error);
		}
	};
}

export const usersStore = new UsersStore();
export const usersStoreContext = createContext(usersStore);
