import clsx from 'clsx';
import { Link } from 'gatsby';
import React, { useEffect, useState, useCallback } from 'react';
import './style.scss';
import { Button } from 'antd';
import { IUser, UsersApi } from '../../services/api/Users';
import Select, { ActionMeta, OptionsOrGroups, GroupBase, Options} from 'react-select';
import { PaymentsApi } from '../../services/api/Payments';
import { IPrice, PricesApi } from '../../services/api/Prices';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// interface IOption {
// 	value: string,
// 	label: string
// }

let style={borderWidth: 1, borderRadius: '4px', borderColor: '#CCCCCC', marginBottom: '10px', width: '100%'}


const AddPaymentForm = (props) => {

	const [userList, setUserList]=useState<Options<IUser>>([]);
	const [user, setUser]=useState<IUser|null>(null);
	const [currency, setCurrency]=useState('');
	const [amount, setAmount]=useState('');
	const [bankInfo, setbankInfo]=useState('');
	const [loading, setLoading]=useState(false);
	const [prices, setPrices]=useState<Options<IPrice>>([]);
	const [price, setPrice]=useState<IPrice|null>(null);
	const [paidTipsCount, setPaidTipsCount]=useState('');
	const [freeTipsCount, setFreeTipsCount]=useState('');

	const [payDate, setPayDate] = useState(new Date());

	const [alertText, setAlertText]=useState<string|null>(null);
	useEffect(()=>{
		UsersApi.getAllWithoutPagination().then((res)=>{
			setUserList(res?.items);
		});

		PricesApi.getAll().then((res)=>{
			setPrices(res?.items);
		});

	},[]);

	const onChange = (option: IUser | null, actionMeta: ActionMeta<IUser>) => {
		console.log(option);
		console.log(option?.id);

		setUser(option);
	}

	const onChangePrice = (option: IPrice | null, actionMeta: ActionMeta<IPrice>) => {
		console.log(option);
		console.log(option?.id);

		setPrice(option);
		if(option){
			setAmount(option.costTip * option.countPaidTips+"");
			setPaidTipsCount(option.countPaidTips+"");
			setFreeTipsCount(option.countFreeTips+"");
		}
	}

	if(!(userList)){
		return null;
	}

	return (
		<div>
			<div>
				<div>Package</div>
				<Select options={prices}
						onChange={onChangePrice}
						getOptionLabel={option=>option.title}
						getOptionValue={option=>(option.id+"")}
						value={price}
						autocomplete={true}
				/>
			</div>
			<div style={{marginTop: '10px'}}>
				<div>User's Email</div>
				<Select options={userList}
						onChange={onChange}
						getOptionLabel={option=>option.email}
						getOptionValue={option=>(option.id+"")}
						value={user}
						autocomplete={true}

				/>
			</div>
			<div style={{marginTop: '10px'}}>
				<div>Payment date</div>
				<DatePicker
					className="input"
					selected={payDate}
					dateFormat={'yyyy-MM-dd'}
					onChange={(date:Date) => setPayDate(date)} />
			</div>
			<div style={{marginTop: '10px'}}>
				<div>Currency</div>
				<input
					className="input"
					value={currency}
					defaultValue={""}
					name="currency"
					onChange={(txt) => {
						let text = txt.target.value;
						setCurrency(text);
					}}
				/>
			</div>
			<div>
				<div>Payment amount</div>
				<input
					className="input"
					value={amount}
					type="number"
					defaultValue={""}
					name="amount"
					onChange={(txt) => {
						let text = txt.target.value;
						let p = parseInt(text);


						if (p >= 0) {
							setAmount(text);
							return;
						}

						if (text === "") {
							setAmount(text);
							return;
						}

						setAmount(amount);
					}}
				/>
			</div>
			<div>
				<div>Free tips count</div>
				<input
					className="input"
					value={freeTipsCount}
					type="number"
					defaultValue={""}
					name="freeTipsCount"
					onChange={(txt) => {
						let text = txt.target.value;
						let p = parseInt(text);


						if (p >= 0) {
							setFreeTipsCount(text);
							return;
						}

						if (text === "") {
							setFreeTipsCount(text);
							return;
						}

						setFreeTipsCount(freeTipsCount);
					}}
				/>
			</div>
			<div>
				<div>Paid tips count</div>
				<input
					className="input"
					value={paidTipsCount}
					type="number"
					defaultValue={""}
					name="paidTipsCount"
					onChange={(txt) => {
						let text = txt.target.value;
						let p = parseInt(text);


						if (p >= 0) {
							setPaidTipsCount(text);
							return;
						}

						if (text === "") {
							setPaidTipsCount(text);
							return;
						}

						setPaidTipsCount(paidTipsCount);
					}}
				/>
			</div>
			<div style={{marginTop: '10px'}}>
				<div>1 request cost</div>
				{/*<input*/}
				{/*	className="input"*/}
				{/*	value={(isNaN(parseInt(amount))||isNaN(parseInt(paidTipsCount))) ? "" : (Math.floor(100*parseInt(amount)/parseInt(paidTipsCount))/100)+""}*/}
				{/*	defaultValue={""}*/}
				{/*	name="currency"*/}
				{/*/>*/}
				<div
					className="input"
					//value={(isNaN(parseInt(amount))||isNaN(parseInt(paidTipsCount))) ? "" : (Math.floor(100*parseInt(amount)/parseInt(paidTipsCount))/100)+""}
					defaultValue={""}
					//name="currency"
				>{(isNaN(parseInt(amount))||isNaN(parseInt(paidTipsCount))) ? "-" : (Math.floor(100*parseInt(amount)/parseInt(paidTipsCount))/100)+""}</div>
			</div>
			<div style={{marginTop: '10px'}}>
				<div>Total requests</div>
				{/*<input*/}
				{/*	className="input"*/}
				{/*	value={(isNaN(parseInt(paidTipsCount))||isNaN(parseInt(freeTipsCount))) ? "" : (parseInt(paidTipsCount)+parseInt(freeTipsCount))+""}*/}
				{/*	defaultValue={""}*/}
				{/*	name="currency"*/}
				{/*/>*/}
				<div
					className="input"
					//value={(isNaN(parseInt(paidTipsCount))||isNaN(parseInt(freeTipsCount))) ? "" : (parseInt(paidTipsCount)+parseInt(freeTipsCount))+""}
					defaultValue={""}
					//name="currency"
				>{(isNaN(parseInt(paidTipsCount)) ? 0 : parseInt(paidTipsCount) )+(isNaN(parseInt(freeTipsCount)) ? 0 : parseInt(freeTipsCount))}</div>
			</div>
			<div>
				<div>Payment information</div>
				<input
					className="input"
					value={bankInfo}
					defaultValue={""}
					name="info"
					onChange={(txt) => {
						let text = txt.target.value;
						setbankInfo(text);
					}}
				/>
			</div>
			<div style={{color: '#e80808', marginTop: '10px'}}>{alertText}</div>
			<Button onClick={()=>{
				let a=parseInt(amount);

				let title=price?.title;

				if((!price)||(price.countFreeTips !== parseInt(freeTipsCount))||(price.countPaidTips !== parseInt(paidTipsCount))){
					title="custom";
				}

				let date=payDate.toISOString().slice(0, 10).replace('T', ' ');
				console.log(date, payDate.toISOString());

				if(user?.id && amount &&  currency && bankInfo && (!isNaN(a)) &&(a>0)){
					PaymentsApi.addPayment({userId: user?.id, date, amount, currency, bankInfo, title, freeTipsCount, paidTipsCount}).then(()=>{
						props.onPaymentsListChange()
						setAlertText(null);
						setCurrency('');
						setUser(null);
						setbankInfo('');
						setAmount('');
						setPrice(null);
						setPaidTipsCount("");
						setFreeTipsCount("");
						setPayDate(new Date());
					}).catch(()=>{

					})
				}else {
					setAlertText("All fields are requited");
				}
			}} type="primary">
				Add payment
			</Button>
		</div>
	);
};

export default AddPaymentForm;
