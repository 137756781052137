import { destroy, get, put, post } from './methods';

export interface IPrice {
	id: number;
	title: string;
	costTip: number;
	countPaidTips: number;
	countFreeTips: number;
	totalPrice: number
}

export const NEW_PRICE: IPrice = {
	id: -1,
	title: '',
	costTip: 0,
	countPaidTips: 0,
	countFreeTips: 0,
	totalPrice: 0
};

export const PricesApi = {
	getAll: get<{items: IPrice[]}>('/prices'),
	buyNow: get<string>('/prices/buy'),
	create: post<IPrice>('/admin/price'),
	edit: put<IPrice>('/admin/price'),
	delete: destroy('/admin/price'),
};
