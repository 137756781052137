import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import DownloadDrawer from '../../components/DownloadDrawer';
import withPrivateRoute from '../../hocs/withPrivateRoute';
import PaymentsTable from '../../modules/Admin/Payments/PaymentsTable';
import { PaymentsApi } from '../../services/api/Payments';
import { Roles } from '../../utils/constants';
import { UsersApi } from '../../services/api/Users';
import { Button } from 'antd';
import AddPaymentForm from '../../components/AddPaymentForm/AddPaymentForm';
import AddPaymentModal from '../../modules/Admin/Payments/AddPaymentModal';

interface Props {}

const Payments = (props: Props) => {

	const [needRefresh, setNeedRefresh]=useState(false);

	useEffect(()=>{

	},[needRefresh]);

	return (
		<>
			<Helmet>
				<title>Payments history</title>
			</Helmet>
			<div className="page">
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<h1 className="page__title">Payments</h1>
					<DownloadDrawer api={PaymentsApi.getExcel} />
				</div>
				<AddPaymentModal onPaymentsListChange={()=>{

					console.log()

					setNeedRefresh(!needRefresh);
				}}/>
				<PaymentsTable needRefresh={needRefresh} setNeedRefresh={setNeedRefresh}/>
			</div>
		</>
	);
};

export default withPrivateRoute(Payments, [Roles.admin]);
