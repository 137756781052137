import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useContext, useState } from 'react';
import { UsersApi } from '../../../services/api/Users';
import { usersStoreContext } from '../../../stores/UsersStore';
import { userStoreContext } from '../../../stores/UserStore';
import { Roles } from '../../../utils/constants';
import AddPaymentForm from '../../../components/AddPaymentForm/AddPaymentForm';

interface Props {
	onPaymentsListChange: any;
}

const AddPaymentModal = (props: Props) => {
	const { user } = useContext(userStoreContext);
	const { tableData } = useContext(usersStoreContext);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onFinish = () => {
		setIsModalVisible(false);
	};

	return (
		<>
			<Button type="primary" onClick={showModal}>
				Add payment
			</Button>

			<Modal footer={null} title="Add new payment" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
				<AddPaymentForm onPaymentsListChange={()=>{
					props.onPaymentsListChange();
					onFinish();
				}}/>
			</Modal>
		</>
	);
};

export default AddPaymentModal;
