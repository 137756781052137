import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import AppTable from '../../../components/tables/AppTable/AppTable';
import { paymentsStoreContext } from '../../../stores/PaymentsStore';
import Actions from '../Users/UsersTable/Actions';
import { Button } from 'antd';
import { PaymentsApi } from '../../../services/api/Payments';

interface Props {
	needRefresh: Boolean;
	setNeedRefresh: any;
}


function getColumns(props: Props) {
	return [
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'user.email',
			sorter: true,
		},
		{
			title: 'Date payment',
			dataIndex: 'payDate',
			key: 'payDate',
			sorter: true,
		},
		{
			title: 'Payment amount',//'Paid',
			dataIndex: 'amount',
			key: 'amount',
			sorter: true,
		},
		{
			title: '1 request cost',//'Paid',
			dataIndex: 'requestCost',
			key: 'requestCost',
			sorter: true,
			render: (_, row) => (isNaN(parseInt(row.amount))||isNaN(parseInt(row.paidTipsCount))||(parseInt(row.paidTipsCount)<=0)) ? "-" : Math.floor(100*parseInt(row.amount)/parseInt(row.paidTipsCount))/100,
		},
		{
			title: 'Total Requests',//'Paid',
			dataIndex: 'totalRequests',
			key: 'totalRequests',
			sorter: true,
			render: (_, row) => (isNaN(parseInt(row.freeTipsCount))||isNaN(parseInt(row.paidTipsCount))) ? "-" : (parseInt(row.paidTipsCount)+parseInt(row.freeTipsCount)),
		},
		{
			title: 'Currency',
			dataIndex: 'currency',
			key: 'currency',
			sorter: true,
		},
		{
			title: 'Payment information',
			dataIndex: 'bankInfo',
			key: 'bankInfo',
			sorter: true,
		},
		{
			title: '',
			render: ({id}) => <Button onClick={()=>{
				PaymentsApi.deletePayment({paymentId: id}).then(()=>{
					props.setNeedRefresh(!props.needRefresh);
				}).catch(()=>{

				})
			}}>Delete</Button>,
		},
	];
}

const PaymentsTable = (props: Props) => {
	const { tableData } = useContext(paymentsStoreContext);

	useEffect(() => {
		tableData.fetch();
	}, [props.needRefresh]);

	const handleTableChange = (pagination, filters, sorter) => {
		if (sorter && sorter.field !== sorter.columnKey) {
			sorter.field = sorter.columnKey;
		}
		tableData.setPage(pagination.current);
		tableData.setSorter(sorter);
	};

	return (
		<AppTable
			key={tableData.pagination.total + '' + tableData.pagination.page}
			columns={getColumns(props)}
			dataSource={tableData.dataSource}
			onChange={handleTableChange}
			pagination={{ ...tableData.pagination }}
		/>
	);
};

export default observer(PaymentsTable);
